import { Router } from '@reach/router';
import {
  isAnalyticsSet,
  Spinner,
  ThemeTransparent,
} from 'basic-frontend-common';
import React from 'react';
import { addPrefetchExcludes, Head, Root, Routes } from 'react-static';
import '../public/basic-frontend.css';
import {
  ANALYTICS_COOKIE_KEY,
  BG_IMAGE,
  ICON_FILE,
  PAGE_AUTHOR,
  PAGE_DESCRIPTION,
  PAGE_KEYWORDS,
  PAGE_NAME,
  PAGE_TOPIC,
} from './constants';

// Any routes that start with 'dynamic' will be treated as non-static routes
addPrefetchExcludes(['dynamic']);

function App() {
  return (
    <>
      {typeof window !== 'undefined' ? (
        <Root>
          <Head>
            {isAnalyticsSet(ANALYTICS_COOKIE_KEY) && (
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-54ZGLRVLZY"
              ></script>
            )}
            <meta charSet="UTF-8" />
            <link rel="icon" href={ICON_FILE} />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta name="theme-color" content="#000000" />
            <meta name="page-topic" content={PAGE_TOPIC} />
            <meta name="robots" content="all" />
            <meta name="revisit-after" content="1 day" />
            <meta name="audience" content="all" />
            <meta http-equiv="expires" content="1 day" />
            <meta http-equiv="cache-control" content="no-cache" />
            <meta
              name="msvalidate.01"
              content="1D150EFBAA80F83F5DB962EEEC207A71"
            />
            <meta
              name="description"
              content={`${PAGE_NAME} ${PAGE_DESCRIPTION}`}
            />
            <meta name="keywords" content={PAGE_KEYWORDS} />
            <meta name="author" content={PAGE_AUTHOR} />

            {isAnalyticsSet(ANALYTICS_COOKIE_KEY) && (
              <script>{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-54ZGLRVLZY');`}</script>
            )}
          </Head>
          <React.Suspense
            fallback={
              <Spinner backgroundImage={BG_IMAGE} theme={ThemeTransparent} />
            }
          >
            <Router>
              <Routes path="*" />
            </Router>
          </React.Suspense>
        </Root>
      ) : null}
    </>
  );
}

export default App;
