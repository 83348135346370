export const PAGE_NAME = 'CDN';
export const PAGE_AUTHOR = 'responsive IT';
export const PAGE_DESCRIPTION = 'finest full stack development';
export const PAGE_TOPIC = 'finest reactive mobile web application';
export const PAGE_KEYWORDS = 'reactjs, css3, html5, responsive';

export const ANALYTICS_COOKIE_KEY = 'cdn_responsive_it_cookie';

export const BG_IMAGE =
  'https://cdn.responsive-it.biz/assets/bg-images/big-data-3.jpg';

export const ICON_FILE = 'https://cdn.responsive-it.biz/assets/icons/icon.png';
